import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
import axios from "axios";
import VueAnalytics from "vue-analytics";
import VueAxios from "vue-axios";
import createPersistedState from "vuex-persistedstate";

import "@/sass/variables.scss";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    search: null
  },
  plugins: [createPersistedState()],
  mutations: {
    auth(state, user) {
      state.user = user;
    },
    logout(state) {
      state.user = null;
      state.search = null;
    },
    search(state, s) {
      state.search = s;
    }
  }
});

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:8081";
} else if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "https://jcc-pm-forms-server.herokuapp.com";
} else {
  axios.defaults.baseURL = "http://localhost:8080";
}

//axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  const u = store.state.user;
  if(u) {
    config.headers.Authorization = `Bearer ${u.token}`;
  }
  return config
}, null, { synchronous: false});

Vue.use(VueAxios, axios);


//and then use it in main.js
Vue.use(VueAnalytics, {
    id: 'UA-42026974-1',
    router
});

Vue.config.productionTip = false;

const DEFAULT_TITLE = "Preventative Maintenace";
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user) {
      next();
      return;
    }
    next({ name: "home" });
  } else if (to.name === "Login" && store.state.user) {
    next({ name: "select " });
  } else next();
});
router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? `${DEFAULT_TITLE} :: ${to.meta.title}`
      : DEFAULT_TITLE;
  });
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
