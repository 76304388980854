<style lang="scss">
  @import 'sass/variables.scss';
</style>
<template>
  <v-app>
    <v-app-bar app color="white" dense>
      <div class="d-flex align-center white rounded-sm">
        <v-img
          alt="Enovity Logo"
          class="mr-2 ml-2"
          contain
          src="./assets/enovity_veolia_nav_logo.png"
          transition="scale-transition"
          width="110"
        />
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y open-on-hover v-if="$store.state.user">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            text
            open-on-hover
            :rounded="false"
          >
            {{ $store.state.user.name }}
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link v-if="isAdmin()">
            <v-list-item-title @click="openAdmin()">Admin</v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title @click="logout()">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-2">
        <router-view />
    </v-main>

    <v-footer fixed color="light-grey-1" class="caption" height="30" elevation="12">
      <p class="text-left">
        © {{new Date().getFullYear()}} Enovity, Inc. All Rights Reserved
        (v2.0.1)
      </p>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    user: null
  }),
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("/");
    },
    isAdmin() {
      this.user = this.$store.state.user;
      return this.user.role === "admin";
    },
    openAdmin() {
      location.href = "https://jcc-pm-forms-server.herokuapp.com/";
    }
  }
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}
</style>
