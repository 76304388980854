import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
      meta: { title: "" }
    },
    {
      path: "/forgot",
      name: "forgot",
      component: () => import("./views/Forgot.vue"),
      meta: { title: "Forgot Password" }
    },
    {
      path: "/reset/:token",
      name: "reset",
      component: () => import("./views/Reset.vue"),
      meta: { title: "Reset Password" }
    },
    {
      path: "/select",
      name: "select",
      component: () => import("./views/List.vue"),
      meta: {
        title: "Select Building",
        requiresAuth: true
      }
    },
    {
      path: "/wo/:id",
      name: "form",
      component: () => import("./views/Form.vue"),
      meta: {
        title: "Work Order",
        requiresAuth: true
      }
    }
  ]
});
